import React, { useMemo } from 'react';
import { Box, Btn, Container, H1, H2, P } from '@saladbob/sassafras';
import Link from 'next/link';
import dayjs from 'dayjs';
import { PiTrophy } from 'react-icons/pi';

import type Tournament from '../../../types/Tournament';
import type { BracketDepth } from '../../../types/Bracket';

type Props = {
    tournament: Tournament,
    bracket?: BracketDepth,
};

const WinnersBlock: React.FC<Props> = ({ tournament, bracket }) => {
    const completedData = useMemo(() => {
        if (tournament?.status === 'finished') {
                const courts = bracket?.courts;
    
                const teams = courts?.map(court => {
                    return court.teams;
                }).flat();
    
                const games = courts?.map(court => {
                    return court.games;
                }).flat();
    
                const trophies = courts?.reduce((acc, court) => {
                    acc.firstPlace.push(court.firstPlace);
                    acc.secondPlace.push(court.secondPlace);
                    acc.thirdPlace.push(court.thirdPlace);
                    acc.tbPlace.push(court.tbPlace);
                    acc.all += 4;
                    return acc;
                }, {
                    firstPlace: [],
                    secondPlace: [],
                    thirdPlace: [],
                    tbPlace: [],
                    all: 0,
                });
                return {
                    courts,
                    teams,
                    games,
                    trophies,
                }
            };
            return null;
    }, [tournament, bracket]);
    return (
        <Box bgColor="tertiary">
            <Container size="lg" padding="xl">
                <H2 font="h1" uppercase mb="md">{tournament.title} {dayjs(tournament.start).format('YYYY')} Final Results!</H2>
                <P font="h3"><strong>{completedData.teams.length}</strong> teams laced up, played through <strong>{completedData.games.length}</strong> intense games across <strong>{completedData.courts.length}</strong> courts, leaving everything they had on the floor.</P>
                <P font="h3"><strong>{completedData.trophies.all}</strong> squads balled out, earning their spot among the elite as <strong>Gus Macker Trophy Earners</strong>—a testament to skill, hustle, and heart.</P>
                <P font="h3">But <strong>{completedData.trophies.firstPlace.length}</strong> teams took it all the way, dominating their courts and standing tall as <strong>First Place Champions!</strong></P>
                <P font="h3">Buckets were made. Ankles were "broken". <strong><i>Legends were born</i></strong>.</P>
                <Link href={`/local/${tournament.host.slug}`}>
                    <Btn
                        tag="span"
                        color="black"
                        outline
                        size="lg"
                        font="leader"
                        iconLeft={<PiTrophy size="32px"/>}
                    >
                        <strong>View Winners</strong>
                    </Btn>
                </Link>
            </Container>
        </Box>
    );
};

export default WinnersBlock;
